import Input from './Input'
import InputError from './InputError'
import InputLabel from './InputLabel'
export { InputError, InputLabel }
export default Input
export type {
  IInput,
  InputStyles,
  FieldError,
  InputCounter
} from './Input.types'
