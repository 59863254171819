import Information from '@carbon/icons-react/lib/Information'
import dynamic from 'next/dynamic'

const Tooltip = dynamic(() => import('lib/ui/Tooltip'))

const InputHintText = ({
  text,
  inputName
}: {
  text: string
  inputName: string
}) => (
  <>
    <Tooltip id={`${inputName}-text-hint`} place='bottom-start' />

    <Information
      size={16}
      data-tooltip-id={`${inputName}-text-hint`}
      data-tooltip-content={text}
      css={{ marginLeft: 5 }}
    />
  </>
)

export default InputHintText
