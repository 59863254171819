import { root } from './Circle.styles'
import { ICircle } from './Circle.types'

const Circle = ({
  circleSize = 6.4,
  background = 'primary',
  color = 'light',
  border,
  styles,
  margin,
  children
}: ICircle) => (
  <div
    data-testid='circle'
    css={root({
      background,
      border,
      color,
      circleSize,
      margin,
      styles
    })}
  >
    {children}
  </div>
)

export default Circle
