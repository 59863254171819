import { memo } from 'react'

import {
  isUiNodeAnchorAttributes,
  isUiNodeImageAttributes,
  isUiNodeInputAttributes,
  isUiNodeScriptAttributes,
  isUiNodeTextAttributes
} from '@ory/integrations/ui'

import NodeAnchor from './NodeAnchor'
import NodeImage from './NodeImage'
import NodeInput from './NodeInput'
import NodeScript from './NodeScript'
import NodeText from './NodeText'
import { NodeProps } from '@/utils/ory/types'

const Node = ({
  node,
  value,
  setValue,
  disabled,
  dispatchSubmit,
  formRef
}: NodeProps) => {
  if (isUiNodeImageAttributes(node.attributes)) {
    return <NodeImage node={node} attributes={node.attributes} />
  }

  if (isUiNodeScriptAttributes(node.attributes)) {
    return <NodeScript node={node} attributes={node.attributes} />
  }

  if (isUiNodeTextAttributes(node.attributes)) {
    return <NodeText node={node} attributes={node.attributes} />
  }

  if (isUiNodeAnchorAttributes(node.attributes)) {
    return <NodeAnchor node={node} attributes={node.attributes} />
  }

  if (isUiNodeInputAttributes(node.attributes)) {
    return (
      <NodeInput
        dispatchSubmit={dispatchSubmit}
        value={value}
        setValue={setValue}
        node={node}
        disabled={disabled}
        attributes={node.attributes}
        formRef={formRef}
      />
    )
  }

  return null
}

export default memo(Node)
