import { createStyles } from 'utils/css'
import { IColor, ISizes } from 'utils/types'

import { InputStyles } from '../Input'

interface IRootStyles {
  /**
   * Pass custom styles to Input
   */
  styles?: InputStyles
  /**
   * Text color for the field
   */
  foreground?: IColor | string
}

export const rootStyles = ({ foreground, styles }: IRootStyles) =>
  createStyles(({ colors }) => {
    const defaultStyles = {
      color: colors[foreground] || foreground,
      display: 'flex',
      alignItems: 'center'
    }
    return { ...defaultStyles, ...styles?.root }
  })

interface ILabelStyles {
  /**
   * Props spread to label for styling
   */
  labelProps?: any
  /**
   * Pass custom styles to Input
   */
  styles?: InputStyles
}

export const labelStyles = ({ labelProps, styles }: ILabelStyles) =>
  createStyles(() => {
    const defaultLabel = {
      lineHeight: 1,
      flex: 1,
      ...labelProps
    }
    return { ...defaultLabel, ...styles?.label }
  })

interface IFieldStyles {
  /**
   * Set background for checkboxes
   */
  background?: IColor | string
  /**
   * Set the size attributes for the input (sets height, border radius and font size)
   */
  inputSize?: keyof ISizes
  /**
   * Pass custom styles to Input
   */
  styles?: InputStyles
}

export const fieldStyles = ({ background, inputSize, styles }: IFieldStyles) =>
  createStyles(({ colors, inputs: { sizes }, spacing }) => {
    const defaultFields = {
      background: background === 'light' && colors.light,
      marginRight: spacing(0.5),
      appearance: 'none',
      minWidth: `calc(${sizes[inputSize]} / 2)`,
      width: `calc(${sizes[inputSize]} / 2)`,
      height: `calc(${sizes[inputSize]} / 2)`,
      border: '1px solid currentColor',
      borderRadius: '0.15em',
      display: 'grid',
      placeContent: 'center',
      cursor: 'pointer',
      '&:before': {
        content: '""',
        width: inputSize === 'lg' ? '1em' : '0.65em',
        height: inputSize === 'lg' ? '1em' : '0.65em',
        transform: 'scale(0)',
        transition: '120ms transform ease-in-out',
        backgroundColor: background === 'light' ? colors.grey25 : colors.light,
        transformOrigin: 'bottom left',
        clipPath: 'polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%)'
      },
      '&:checked': {
        background: background !== 'light' && (colors[background] || background)
      },
      '&:checked::before': {
        transform: 'scale(1)'
      }
    }
    return { ...defaultFields, ...styles?.field }
  })
