import { createStyles } from 'utils/css'
import theme from 'utils/theme'

import { defaults } from '.'
import { ContentStyles, IDefaultLayout } from './types'
const { mediaQuery, spacing } = theme
const borderSize = 2

export const grid = ({
  backgroundImage
}: Pick<IDefaultLayout, 'backgroundImage'>) =>
  createStyles(({ mediaQuery, size }) => ({
    background:
      backgroundImage && `url(${backgroundImage}) no-repeat -50% bottom`,
    backgroundSize: '50%',
    height: '100vh',
    [mediaQuery(defaults.breakpoint)]: {
      background: 'none',
      display: 'grid',
      alignItems: 'stretch',
      gridTemplateColumns: `${size(defaults.breakpointSideWidth)} auto`
    },
    [`@media (min-width: ${defaults.lgBreakpoint})`]: {
      gridTemplateColumns: `${size(defaults.lgBreakpointSideWidth)} auto`
    }
  }))

export const sideWrap = ({
  brandColor,
  sideBackground
}: Pick<IDefaultLayout, 'sideBackground' | 'brandColor'>) =>
  createStyles(({ colors, mediaQuery, size }) => ({
    position: 'relative',
    zIndex: 1,
    padding: spacing(1.5),
    background: !brandColor && colors.primary,
    [mediaQuery(defaults.breakpoint)]: {
      background:
        sideBackground &&
        ` url(${sideBackground}) no-repeat right bottom ${colors.light}`,
      borderRight: `${borderSize}px solid ${colors.grey10}`,
      padding: spacing(3),
      maxWidth: size(50)
    }
  }))

export const circleWrap = () =>
  createStyles(({ colors, mediaQuery, size }) => ({
    display: 'none',
    [mediaQuery(defaults.breakpoint)]: {
      display: 'block',
      height: size(4.8),
      width: size(4.8),
      position: 'absolute',
      right: 0,
      clipPath: 'circle(50% at 100% 50%)',
      background: colors.grey10,
      top: size(15)
    }
  }))

export const circle = () =>
  createStyles(({ colors, size }) => ({
    display: 'block',
    position: 'absolute',
    right: `-${borderSize}px`,
    height: size(4.8),
    width: size(2.4),
    clipPath: 'circle(60% at 95% 50%)',
    background: colors.light
  }))

export const side = ({ brandColor }: Pick<IDefaultLayout, 'brandColor'>) =>
  createStyles(({ justifications }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: 16,
    flexDirection: brandColor ? 'column' : 'row',
    justifyContent: justifications.between,
    height: '100%',
    [mediaQuery(defaults.breakpoint)]: {
      flexDirection: 'column'
    }
  }))

export const sideContent = () =>
  createStyles(({ mediaQuery, spacing }) => ({
    flex: 1,
    [mediaQuery(defaults.breakpoint)]: {
      margin: spacing([1.5, 0, 3])
    }
  }))

export const sideBottom = () =>
  createStyles(({ mediaQuery, size, spacing }) => ({
    display: 'none',
    [mediaQuery(defaults.breakpoint)]: {
      marginTop: spacing(1),
      maxWidth: size(17),
      display: 'block'
    }
  }))

export const contactBtn = () =>
  createStyles(({ colors, spacing }) => ({
    margin: `${spacing(1)} auto 0`,
    '& button': {
      color: colors.grey20,
      border: `1px solid ${colors.grey15}`,
      margin: 'auto',
      display: 'block'
    }
  }))

export const contentWrap = ({
  backgroundImage,
  center,
  formId,
  prevUrl,
  styles
}: Pick<
  IDefaultLayout,
  'backgroundImage' | 'center' | 'formId' | 'prevUrl' | 'styles'
>) =>
  createStyles(({ mediaQuery, spacing, treatments }) => {
    const placement = center
      ? { ...treatments.center, flexDirection: 'column' }
      : {}
    const defaultStyles = {
      padding: formId || prevUrl ? spacing([1.5, 1.5, 9]) : spacing(1.5),
      zIndex: 2,
      [mediaQuery(defaults.breakpoint)]: {
        background:
          backgroundImage && `url(${backgroundImage}) no-repeat -50% bottom`,
        backgroundSize: '50%',
        padding: formId || prevUrl ? spacing([3, 3, 6]) : spacing(3),
        ...placement
      },
      '& > div': {
        width: '100%'
      }
    }

    return { ...defaultStyles, ...styles?.content }
  })

export const contentBox = ({ center }: Pick<IDefaultLayout, 'center'>) => {
  const centeredStyles: ContentStyles = {
    outer: {
      width: '100%'
    },
    inner: {
      margin: 'auto'
    }
  }
  const defaultStyles: ContentStyles = {
    inner: { margin: 0 }
  }
  return !center ? defaultStyles : centeredStyles
}

export const actions = () =>
  createStyles(({ colors, justifications, size, shadows, spacing }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: 12,
    justifyContent: justifications.between,
    boxShadow: shadows.light,
    background: colors.light,
    borderTop: `1px solid ${colors.grey15}`,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    minHeight: size(7.2),
    padding: spacing([0.75, 1]),
    zIndex: 1,
    [mediaQuery('sm', 'max-width')]: {
      flexDirection: 'column',
      '& button > svg': {
        display: 'none'
      }
    },
    [mediaQuery(defaults.breakpoint)]: {
      left: size(defaults.breakpointSideWidth)
    },
    [`@media (min-width: ${defaults.lgBreakpoint})`]: {
      left: size(defaults.lgBreakpointSideWidth)
    }
  }))

export const btn = {
  marginLeft: 'auto',
  '& > svg': {
    marginLeft: spacing(0.5)
  },
  [mediaQuery('sm', 'max-width')]: {
    margin: 0
  }
}
