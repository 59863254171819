import { useCallback, useEffect, useRef } from 'react'

type DebounceOptions = {
  delay: number
  maxWait?: number
}

const useDebounce = <T extends (...args: any[]) => void>(
  callback: T,
  options: DebounceOptions
) => {
  const { delay, maxWait } = options
  const timerRef = useRef<NodeJS.Timeout>()
  const latestCallbackRef = useRef<T>()

  latestCallbackRef.current = callback

  const debouncedCallback = useCallback(
    (...args: Parameters<T>) => {
      const invokeCallback = () => {
        latestCallbackRef.current?.(...args)
        timerRef.current = undefined
      }

      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }

      if (maxWait !== undefined) {
        timerRef.current = setTimeout(invokeCallback, maxWait)
      }

      timerRef.current = setTimeout(invokeCallback, delay)
    },
    [delay, maxWait]
  )

  const cancel = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
      timerRef.current = undefined
    }
  }, [])

  useEffect(() => cancel, [])

  return {
    debounced: debouncedCallback as (...args: Parameters<T>) => void,
    cancel
  }
}

export default useDebounce
