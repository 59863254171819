/* eslint-disable no-case-declarations */
import { CSSProperties } from 'react'

import { UiNode, UiNodeTextAttributes, UiText } from '@ory/client'
import Box from 'lib/ui/Box'
import ContentScroll from 'lib/ui/ContentScroll'
import Text from 'lib/ui/Text'
import theme from 'utils/theme'

interface Props {
  node: UiNode
  attributes: UiNodeTextAttributes
}

const backupCodesStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: theme.spacing(0.5),
  marginBottom: theme.spacing(1.5)
}

const Content = ({ attributes }: Props) => {
  switch (attributes.text.id) {
    case 1050015:
      // This text node contains lookup secrets. Let's make them a bit more beautiful!
      const secrets = (attributes.text?.context as any).secrets.map(
        (text: UiText, k: number) => (
          <Box
            key={k}
            padding={[0.5, 0.5]}
            background='grey10'
            borderRadius='lg'
          >
            <Text data-testid={`node/text/${attributes.id}/lookup_secret`}>
              {/* Used lookup_secret has ID 1050014 */}
              <code>{text.id === 1050014 ? 'Used' : text.text}</code>
            </Text>
          </Box>
        )
      )
      return (
        <div
          data-testid={`node/text/${attributes.id}/text`}
          style={backupCodesStyle}
        >
          {secrets}
        </div>
      )
  }

  return (
    <div data-testid={`node/text/${attributes.id}/text`}>
      <ContentScroll maxHeight='200'>{attributes.text.text}</ContentScroll>
    </div>
  )
}

const NodeText = ({ node, attributes }: Props) => {
  const setText = () => {
    switch (node?.meta?.label?.id) {
      // These are your back up recovery codes. Please keep them in a safe place!
      case 1050010:
        return ''

      // This is your authenticator app secret. Use it if you can not scan the QR code.
      case 1050017:
        return 'Can’t scan the code? Copy the code below and enter manually into the app.'

      default:
        return node.meta.label.text
    }
  }

  const text = setText()

  return (
    <>
      {text && (
        <Text
          margin={[0, 0, 1]}
          data-testid={`node/text/${attributes.id}/label`}
        >
          {text}
        </Text>
      )}
      <Content node={node} attributes={attributes} />
    </>
  )
}

export default NodeText
