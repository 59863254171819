import { createStyles } from 'utils/css'

import { IAlertStyles } from './Alert.types'

export const css = ({
  alertSize,
  padding,
  margin,
  showIcon,
  variant
}: IAlertStyles) =>
  createStyles(({ alignments, colors, fontSizes, radii, spacing }) => {
    const setColors = () => {
      switch (variant) {
        case 'danger':
          return {
            background: colors.redBackground,
            icon: colors.danger
          }
        case 'warning':
          return {
            background: colors.yellowBackground,
            icon: colors.warning
          }
        case 'success':
          return {
            background: colors.greenBackground,
            icon: colors.success
          }
        default:
          return {
            background: colors.grey5,
            icon: colors.primary
          }
      }
    }
    return {
      backgroundColor: setColors().background,
      borderRadius: radii.lg,
      color: colors.purple30,
      fontSize: fontSizes[alertSize],
      padding: spacing(padding),
      width: '100%',
      margin: margin && spacing(margin),
      display: showIcon ? 'flex' : 'inline-block',
      alignItems: alignments.center,
      textAlign: 'left',
      '& svg': {
        fill: setColors().icon,
        marginRight: spacing(1),
        flex: 'none'
      }
    }
  })
