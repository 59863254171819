import Icon from 'lib/ui/Icon'
import { IColor } from 'utils/types'

const Logo = () => (
  <Icon
    name='altLogo'
    color='yellow20'
    height={6.4}
    width={5.6}
    viewBox='0 0 114 128'
  />
)

export const AltLogoDashboard = ({ color = 'light' }: { color?: IColor }) => (
  <Icon
    name='altLogoDashboard'
    color={color}
    viewBox='0 0 37 42'
    height={4.2}
    width={3.7}
  />
)

export const AlternativePaymentsLogo = ({
  color = 'light'
}: {
  color?: IColor
}) => (
  <Icon
    color={color}
    name='alternativePaymentsLogo'
    height={4.5}
    width={16.4}
    viewBox='0 0 164 45'
    title='Alternative Payments logo'
  />
)

export const AltLogo = () => (
  <Icon
    name='altLogoText'
    color='yellow20'
    viewBox='0 0 128 26'
    height={5}
    width={22}
  />
)

export default Logo
