import { root } from './Card.styles'
import { ICard } from './Card.types'

const Card = ({
  children,
  padding = 2,
  borderRadius = 'md',
  borderColor = 'grey10',
  borderWidth = 1,
  borderStyle = 'solid',
  background = 'light',
  shadow = 'soft',
  alignment,
  foreground,
  fullHeight,
  margin,
  styles,
  width
}: ICard) => (
  <div
    data-testid='card'
    css={root({
      alignment,
      background,
      borderColor,
      borderRadius,
      borderStyle,
      borderWidth,
      foreground,
      fullHeight,
      margin,
      padding,
      shadow,
      styles,
      width
    })}
  >
    {children}
  </div>
)

export default Card
