import { useEffect, useState } from 'react'

import Success from '@carbon/icons-react/lib/CheckmarkOutline'
import Info from '@carbon/icons-react/lib/InformationFilled'
import Warning from '@carbon/icons-react/lib/WarningAltFilled'

import { css } from './Alert.styles'
import { IAlert } from './Alert.types'

const Alert = ({
  alertSize = 'md',
  padding = 2,
  children,
  onClose,
  timeout,
  showIcon,
  variant,
  margin
}: IAlert) => {
  const [isHidden, setHidden] = useState(false)
  const iconSize = alertSize === 'lg' ? 32 : 24

  useEffect(() => {
    if (timeout) {
      setTimeout(() => {
        setHidden(true)
        onClose()
      }, timeout)
    }
  }, [timeout])

  const RenderIcon = () => {
    switch (variant) {
      case 'danger':
      case 'warning':
        return <Warning size={iconSize} />
      case 'info':
        return <Info size={iconSize} />
      default:
        return <Success size={iconSize} />
    }
  }
  if (isHidden) return null
  return (
    <div
      role='alert'
      css={css({ alertSize, padding, margin, showIcon, variant })}
    >
      {showIcon && <RenderIcon />}
      <div css={{ wordWrap: 'break-word' }}>{children}</div>
    </div>
  )
}

export default Alert
