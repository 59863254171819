import { capitalizeFirstLetter } from 'utils/misc'

import * as css from './Input.styles'
import { IInputError } from './Input.types'

const InputError = ({ error }: IInputError) => (
  <div css={css.errorContainerStyles}>
    {capitalizeFirstLetter(error?.message)}
  </div>
)
export default InputError
