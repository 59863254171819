import * as css from './ContentScroll.styles'
import { IContentScroll } from './ContentScroll.types'

const ContentScroll = ({ children, maxHeight, styles }: IContentScroll) => (
  <div data-testid='contentscroll' css={css.root({ maxHeight, styles })}>
    {children}
  </div>
)

export default ContentScroll
