import { getNodeLabel } from '@ory/integrations/ui'
import Checkbox from 'lib/ui/Checkbox'

import Messages from '@/ui/Messages'
import { NodeInputProps } from '@/utils/ory/types'

const NodeInputCheckbox = ({
  node,
  attributes,
  setValue,
  disabled
}: NodeInputProps) => {
  const error = node.messages.find(({ type }) => type === 'error')
  return (
    <>
      <Checkbox
        name={attributes.name}
        onChange={e => setValue(e.target.checked)}
        disabled={attributes.disabled || disabled}
        label={getNodeLabel(node)}
        error={error && { type: error.type, message: error.text }}
      />
      {node.messages && node.messages.length > 0 && (
        <Messages messages={node.messages} />
      )}
    </>
  )
}

export default NodeInputCheckbox
