import { Suspense } from 'react'

import dynamic from 'next/dynamic'

const NodeCode = dynamic(() => import('@/ui/Node/NodeCode'))

import { errorMessage } from './utils'
import Input, { Password } from '@/ui/Input/v2'
import { NodeInputProps } from '@/utils/ory/types'

const NodeInputDefault = ({
  node,
  attributes,
  setValue,
  disabled,
  value,
  dispatchSubmit,
  formRef
}: NodeInputProps) => {
  // Some attributes have dynamic JavaScript - this is for example required for WebAuthn.
  const onClick = () => {
    // This section is only used for WebAuthn. The script is loaded via a <script> node
    // and the functions are available on the global window level. Unfortunately, there
    // is currently no better way than executing eval / function here at this moment.
    if (attributes.onclick) {
      const run = new Function(attributes.onclick)
      run()
    }
  }
  const setLabel = () => {
    switch (attributes.name) {
      case 'traits.first_name':
        return 'First Name'
      case 'traits.last_name':
        return 'Last Name'
      case 'email':
        return 'Email address'
      case 'traits.email':
        return 'Email address'
      case 'identifier':
        return 'Email address'
      default:
        return node.meta.label?.text || 'Enter your input here'
    }
  }

  const error = node.messages.find(
    ({ type }) => type === 'error' || type === 'info'
  )

  if (['code', 'totp_code'].includes(attributes.name)) {
    return (
      <Suspense fallback={null}>
        <NodeCode
          node={node}
          attributes={attributes}
          setValue={setValue}
          value={value}
          dispatchSubmit={dispatchSubmit}
          disabled={disabled}
          formRef={formRef}
        />
      </Suspense>
    )
  }

  if (attributes.name === 'lookup_secret') {
    return (
      <Suspense fallback={null}>
        <NodeCode
          length={8}
          node={node}
          attributes={attributes}
          setValue={setValue}
          value={value}
          dispatchSubmit={dispatchSubmit}
          disabled={disabled}
        />
      </Suspense>
    )
  }

  const Tag = attributes.type === 'password' ? Password : Input

  return (
    <Tag
      label={node.meta.label?.text}
      hideLabel
      placeholder={setLabel()}
      onClick={onClick}
      value={value}
      onChange={e => setValue(e.target.value)}
      type={attributes.type}
      name={attributes.name}
      disabled={attributes.disabled || disabled}
      error={
        error && {
          type: error.type,
          message: errorMessage(error)
        }
      }
    />
  )
}

export default NodeInputDefault
