import Locked from '@carbon/icons-react/lib/Locked'
import { UiNode } from '@ory/client'
import { getNodeLabel } from '@ory/integrations/ui'
import Box from 'lib/ui/Box'
import Circle from 'lib/ui/Circle'
import Text from 'lib/ui/Text'
import Image from 'next/image'
import { capitalizeFirstLetter } from 'utils/misc'
import theme from 'utils/theme'

import * as css from './styles'
import { isPasswordless, isResendCode } from './utils'
import Button from '@/ui/Button/v2'
import { NodeInputProps } from '@/utils/ory/types'

const ExtraContent = ({ node }: { node: UiNode }) => {
  // Unlink TOTP Authenticator App
  if (node?.meta?.label?.id === 1050004) {
    return (
      <>
        <Box padding={[1, 0, 0]}>
          <Circle background='green10' color='green30'>
            <Locked size={32} />
          </Circle>
        </Box>

        <Text alignment='center' margin={[0, 0, 1]}>
          Your account is currently protected with multi-factor authentication.
        </Text>
      </>
    )
  }
}

const NodeInputSubmit = ({ node, attributes, disabled }: NodeInputProps) => {
  const isSSO =
    attributes.name === 'provider' ||
    attributes.name === 'link' ||
    attributes.name === 'unlink'
  const isSubmitPassword =
    attributes.name === 'method' &&
    attributes.value === 'password' &&
    node.meta?.label?.id !== 1070003

  const setVariant = () => {
    if (isSubmitPassword || isResendCode(node)) return 'outline'
    if (isPasswordless(node)) return 'primary'

    switch (attributes.name) {
      case 'provider':
      case 'link':
      case 'unlink':
        return 'outline'
      case 'email':
        return 'text'
      default:
        return 'primary'
    }
  }

  const setButtonText = () => {
    switch (node?.meta?.label?.id) {
      // Use Authenticator
      case 1010009:
        return 'Verify'

      // Use backup recovery code
      case 1010010:
        return 'Use code'

      // Unlink TOTP Authenticator App
      case 1050004:
        return 'Disable MFA'

      // Disable this method (lookup_secret_disable)
      case 1050016:
        return 'Disable backup recovery codes'

      // Generate new backup recovery codes
      case 1050008:
        return 'Generate backup recovery codes'

      default:
        return getNodeLabel(node)
    }
  }

  return (
    <>
      <ExtraContent node={node} />

      <Button
        name={attributes.name}
        value={attributes.value || ''}
        type='submit'
        display='block'
        disabled={attributes.disabled || disabled}
        variant={setVariant()}
        styles={setVariant() !== 'primary' && css.buttonStyle(isSSO)}
      >
        {isSSO ? (
          <>
            <Image
              src={`/${attributes.value}-logo.svg`}
              width='18'
              height='18'
              alt={`${attributes.value} logo`}
            />
            <span css={{ marginLeft: theme.spacing(1) }}>
              Continue with{' '}
              {capitalizeFirstLetter(attributes.value.toLowerCase())}
            </span>
          </>
        ) : (
          setButtonText()
        )}
      </Button>
    </>
  )
}
export default NodeInputSubmit
