import { UiText } from '@ory/client'
import Alert from 'lib/ui/Alert'
import Link from 'next/link'

import { UiTextWithDuplicateIdentifier } from '@/utils/ory/types'
interface MessageProps {
  message: UiText
}

export const Message = ({ message }: MessageProps) => {
  const setMessage = () => {
    switch (message.id) {
      case 1060001:
        return 'You successfully recovered your account. Please change your password below.'
      case 4000006:
        return 'The provided credentials are invalid. Check for typos in your email address or password.'
      case 1010016:
        return `Signing in will link your account to "${(
          message.context as UiTextWithDuplicateIdentifier
        )
          ?.duplicateIdentifier}". If you do not wish to link that account, please click on "Change email".`
      case 4000010:
        return (
          <Link href='/verification'>
            Account not active, please verify account now.
          </Link>
        )
      default:
        return message.text
    }
  }
  return (
    <Alert
      alertSize='sm'
      showIcon
      padding={[1, 1.5]}
      variant={message.type === 'error' ? 'danger' : message.type}
    >
      {setMessage()}
    </Alert>
  )
}

interface MessagesProps {
  messages?: Array<UiText>
}

const Messages = ({ messages }: MessagesProps) => {
  if (!messages) {
    return null
  }

  return (
    <div>
      {messages.map(message => (
        <Message key={message.id} message={message} />
      ))}
    </div>
  )
}

export default Messages
