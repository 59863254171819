import { ForwardedRef, forwardRef, useState } from 'react'

import View from '@carbon/icons-react/lib/View'
import ViewOff from '@carbon/icons-react/lib/ViewOff'
import { InputLabel, InputError } from 'lib/ui/Input/v2'
import * as css from 'lib/ui/Input/v2/Input.styles'
import theme from 'utils/theme'

import { IInputPassword } from './InputPassword.types'

const InputPasswordRef = (
  {
    inputSize = 'lg',
    testid,
    disabled,
    error,
    name,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    styles,
    value,
    currency,
    label,
    foreground,
    hideLabel,
    id,
    labelProps,
    required,
    icon,
    ref: inputStylesRef
  }: IInputPassword,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const togglePasswordVisibility = () => setShowPassword(!showPassword)

  return (
    <div css={css.rootStyles({ currency, inputSize, styles })}>
      <InputLabel
        inputSize={inputSize}
        foreground={foreground}
        label={label}
        name={name}
        error={error}
        hideLabel={hideLabel}
        id={id}
        labelProps={labelProps}
        required={required}
        styles={styles}
      />
      <div
        css={{
          position: 'relative',
          color: error ? theme.colors.danger : 'inherit'
        }}
      >
        <input
          css={css.fieldStyles({
            error,
            foreground,
            hideLabel,
            inputSize,
            labelProps,
            ref: inputStylesRef,
            label,
            name,
            styles: {
              field: {
                ...styles?.field,
                borderRadius: theme.inputs.radii.sm,
                paddingLeft: icon ? theme.spacing(2.5) : theme.spacing(1),
                paddingRight: theme.spacing(2.5)
              }
            }
          })}
          aria-invalid={error ? 'true' : 'false'}
          aria-labelledby={`${name}-label`}
          data-testid={testid}
          disabled={disabled}
          id={name}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          placeholder={placeholder}
          type={showPassword ? 'text' : 'password'}
          ref={ref}
          value={value}
        />
        <div
          css={{
            position: 'absolute',
            left: theme.spacing(1),
            height: '16px',
            top: '50%',
            transform: 'translateY(-50%)',
            display: 'flex'
          }}
        >
          {icon}
        </div>
        <button
          type='button'
          onClick={togglePasswordVisibility}
          css={{
            position: 'absolute',
            right: theme.spacing(1),
            height: '16px',
            top: '50%',
            transform: 'translateY(-50%)',
            cursor: 'pointer',
            border: 'none',
            background: 'transparent',
            display: 'flex'
          }}
        >
          {showPassword ? <ViewOff size={16} /> : <View size={16} />}
        </button>
      </div>
      {error && <InputError error={error} />}
    </div>
  )
}

const InputPassword = forwardRef(InputPasswordRef)

export default InputPassword
