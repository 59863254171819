/* eslint-disable jsx-a11y/anchor-is-valid */
import Button, { IButtonProps, Variant } from 'lib/ui/Button'
import theme from 'utils/theme'

const { colors, radii } = theme
import { usePartnerContext } from '@/hooks/providers/PartnerProvider'

const textVariantFix = {
  textDecoration: 'none !important',
  '&:disabled': {
    color: 'inherit'
  }
}

/**
 * Need to override old theme styles
 * until all apps go live with branding updates
 */
export const setBtnStyles = (variant: Variant) => {
  const { brandColor } = usePartnerContext()

  switch (variant) {
    case 'primary':
      return brandColor.hasCustomBrandColor
        ? {
            background: brandColor?.hex,
            color: brandColor.isDark ? colors.light : colors.grey25,
            '&:hover': {
              background: brandColor?.hex
            }
          }
        : {
            background: colors.gradientPurple,
            color: colors.light,
            '&:hover': {
              background: colors.primary
            }
          }
    case 'text':
      return brandColor.hasCustomBrandColor
        ? {
            color: brandColor.hasLowContrastToWhite
              ? colors.grey30
              : brandColor?.hex,
            ...textVariantFix
          }
        : {
            color: colors.primary,
            ...textVariantFix
          }
    default:
      return {}
  }
}

const BaseButton = ({
  buttonSize,
  display,
  variant,
  type,
  children,
  disabled,
  href,
  form,
  id,
  loading,
  name,
  newTab,
  onClick,
  value,
  brandColor,
  iconPlacement,
  padding,
  styles
}: IButtonProps) => (
  <Button
    buttonSize={buttonSize}
    display={display}
    variant={variant}
    type={type}
    disabled={disabled}
    href={href}
    form={form}
    id={id}
    loading={loading}
    name={name}
    newTab={newTab}
    onClick={onClick}
    value={value}
    brandColor={brandColor}
    iconPlacement={iconPlacement}
    padding={padding}
    styles={{ ...styles, ...setBtnStyles(variant), borderRadius: radii.xxl }}
  >
    {children}
  </Button>
)

export default BaseButton

export type { IButtonProps }
