import NodeInputButton from './NodeInputButton'
import NodeInputCheckbox from './NodeInputCheckbox'
import NodeInputDefault from './NodeInputDefault'
import NodeInputHidden from './NodeInputHidden'
import NodeInputSubmit from './NodeInputSubmit'
import { NodeInputProps } from '@/utils/ory/types'

const NodeInput = ({
  attributes,
  node,
  value,
  disabled,
  dispatchSubmit,
  setValue,
  formRef
}: NodeInputProps) => {
  switch (attributes.type) {
    case 'hidden':
      return (
        <NodeInputHidden
          attributes={attributes}
          node={node}
          value={value}
          disabled={disabled}
          dispatchSubmit={dispatchSubmit}
          setValue={setValue}
        />
      )
    case 'checkbox':
      return (
        <NodeInputCheckbox
          node={node}
          attributes={attributes}
          setValue={setValue}
          disabled={disabled}
          value={value}
          dispatchSubmit={dispatchSubmit}
        />
      )
    case 'button':
      return (
        <NodeInputButton
          node={node}
          attributes={attributes}
          setValue={setValue}
          disabled={disabled}
          dispatchSubmit={dispatchSubmit}
          value={value}
        />
      )
    case 'submit':
      return (
        <NodeInputSubmit
          node={node}
          attributes={attributes}
          disabled={disabled}
          dispatchSubmit={dispatchSubmit}
          value={value}
          setValue={setValue}
        />
      )
  }

  // Render a generic text input field.
  return (
    <NodeInputDefault
      node={node}
      attributes={attributes}
      disabled={disabled}
      dispatchSubmit={dispatchSubmit}
      value={value}
      setValue={setValue}
      formRef={formRef}
    />
  )
}
export default NodeInput
