import { NodeInputProps } from '@/utils/ory/types'

const NodeInputHidden = ({ attributes }: NodeInputProps) => (
  <input
    type={attributes.type}
    name={attributes.name}
    value={attributes.value || 'true'}
  />
)

export default NodeInputHidden
