import { useState, FormEvent } from 'react'

import useDebounce from 'lib/hooks/useDebounce'

import { FormValues } from '@/utils/ory/types'

const useFormSubmit = <T extends FormValues>(
  onSubmit: (data: T) => Promise<void>
) => {
  const [isLoading, setIsLoading] = useState(false)
  const debouncedSubmit = useDebounce(
    (body: T) => {
      onSubmit(body).then(() => setIsLoading(false))
    },
    {
      delay: 300
    }
  )

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.stopPropagation()
    event.preventDefault()
    setIsLoading(true)

    const form = event.currentTarget as HTMLFormElement
    const formData = new FormData(form)
    let body: T = Object.fromEntries(formData) as T
    const hasSubmitter = (evt: any): evt is { submitter: HTMLInputElement } =>
      'submitter' in evt

    if (hasSubmitter(event.nativeEvent)) {
      const method = event.nativeEvent.submitter
      body = {
        ...body,
        ...{ [method.name]: method.value }
      }
    }

    debouncedSubmit.debounced(body)
  }

  return { handleSubmit, isLoading }
}

export default useFormSubmit
