import Box from 'lib/ui/Box'
import Placeholder from 'lib/ui/Placeholder'

import FormDivider from '@/ui/FormDivider'
/**
 * @returns Skeleton loader for forms
 */

export interface ISkeletonLoader {
  divider?: boolean
  btnItems?: number
  formItems?: number
}

const SkeletonLoader = ({
  divider,
  btnItems = 1,
  formItems
}: ISkeletonLoader) => {
  if (!formItems) return null
  return (
    <div data-testid='form-loader'>
      {Array.from({ length: btnItems }, (_, index) => (
        <Box key={`btn-item-${index}`} margin={[0, 0, 1]} padding={0}>
          <Placeholder borderRadius='xxl' animate />
        </Box>
      ))}

      {divider && (
        <Box margin={[2, 0]} padding={0}>
          <FormDivider />
        </Box>
      )}

      {Array.from({ length: formItems }, (_, index) => (
        <Box key={`form-item-${index}`} margin={[0, 0, 1]} padding={0}>
          <Placeholder borderRadius='xxl' animate />
        </Box>
      ))}
    </div>
  )
}

export default SkeletonLoader
