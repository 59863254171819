import LayoutTransition from 'lib/ui/Animations/LayoutTransition'
import Box from 'lib/ui/Box'
import Heading from 'lib/ui/Heading'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { DefaultSeo } from 'next-seo'
import { animateIn } from 'utils/animations'

import * as css from './styles'
import { IDefaultLayout, IDefaults } from './types'

const ActionBar = dynamic(() => import('./Actions'))
const StepsMeter = dynamic(() => import('lib/ui/StepsMeter'))

export const defaults: IDefaults = {
  breakpoint: 'lg',
  breakpointSideWidth: 35,
  lgBreakpoint: '1200px',
  lgBreakpointSideWidth: 50
}

const DefaultLayout = ({
  alignment,
  brandColor,
  children,
  formId,
  headline,
  headlineProps,
  logo,
  nextUrl,
  prevUrl,
  sideBottom,
  sideContent,
  stepProps,
  title,
  width = 88,
  backgroundImage,
  sideBackground,
  center,
  styles,
  ctaContent,
  disabled,
  loading,
  loadingIcon
}: IDefaultLayout) => {
  const { route } = useRouter()

  const setAnimationProps = () => {
    switch (route) {
      case '/':
        return animateIn({ duration: 0.5, transition: 'slide', x: 0, y: -50 })
      case '/onboard':
      case '/[invoice]':
        return animateIn({ duration: 0.5, transition: 'slide', x: 0, y: 50 })
      default:
        return animateIn({ duration: 0.5, transition: 'slide', x: -50, y: 0 })
    }
  }

  return (
    <>
      <DefaultSeo title={title || headline} />
      <div css={css.grid({ backgroundImage })}>
        <div css={css.sideWrap({ brandColor, sideBackground })}>
          <div css={css.side({ brandColor })}>
            <header>{logo}</header>
            {sideContent && <div css={css.sideContent}>{sideContent}</div>}
            {sideBottom && <div css={css.sideBottom}>{sideBottom}</div>}
          </div>
          <span css={css.circleWrap}>
            <span css={css.circle}></span>
          </span>
        </div>
        <div
          css={css.contentWrap({
            backgroundImage,
            center,
            formId,
            prevUrl,
            styles
          })}
        >
          <LayoutTransition {...setAnimationProps()}>
            <Box
              tag='main'
              width={width}
              alignment={alignment}
              padding={0}
              styles={css.contentBox({ center })}
            >
              {stepProps && (
                <StepsMeter brandColor={brandColor} {...stepProps} />
              )}
              {headline && (
                <Heading
                  tag='h1'
                  size='lg'
                  color='grey25'
                  alignment={alignment}
                  margin={[0, 0, 2]}
                  {...headlineProps}
                >
                  {headline}
                </Heading>
              )}
              {children}

              {(formId || prevUrl || nextUrl) && (
                <ActionBar
                  brandColor={brandColor}
                  ctaContent={ctaContent}
                  disabled={disabled}
                  formId={formId}
                  loading={loading}
                  loadingIcon={loadingIcon}
                  nextUrl={nextUrl}
                  prevUrl={prevUrl}
                />
              )}
            </Box>
          </LayoutTransition>
        </div>
      </div>
    </>
  )
}

export default DefaultLayout
