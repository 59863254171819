import theme from 'utils/theme'

const dividerStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '24px',
  marginTop: '32px',
  color: theme.colors.surface400,
  '&:before, &:after': {
    content: '""',
    flex: 1,
    borderBottom: `1px solid ${theme.colors.surface300}`
  }
}

const FormDivider = ({ showOr = true }: { showOr?: boolean }) => (
  <>
    <div css={dividerStyles}>
      {showOr && (
        <span
          css={{ width: '50px', fontSize: theme.typography.caption.sizes.md }}
        >
          OR
        </span>
      )}
    </div>
  </>
)

export default FormDivider
