import { createStyles } from 'utils/css'

import { ICircleStyles } from './Circle.types'

export const root = ({
  background,
  border,
  color,
  circleSize,
  margin,
  styles
}: ICircleStyles) =>
  createStyles(({ colors, treatments, size, spacing }) => {
    const defaultStyles = {
      background: colors[background] || background,
      border: border && border,
      color: colors[color] || color,
      margin: !margin ? `0 auto ${spacing(1)}` : margin,
      borderRadius: '100%',
      width: size(circleSize),
      height: size(circleSize),
      ...treatments.center
    }
    return { ...defaultStyles, ...styles }
  })
