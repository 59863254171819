/* eslint-disable @next/next/no-img-element */
import { UiNode, UiNodeImageAttributes } from '@ory/client'
import Text from 'lib/ui/Text'

interface Props {
  node: UiNode
  attributes: UiNodeImageAttributes
}

const QrCode = ({ node, attributes }: Props) => (
  <>
    <Text margin={[0, 0, 1]}>
      Scan the QR code below using the software of your choice, such as Authy or
      Google Authenticator.
    </Text>

    <img
      data-testid={`node/image/${attributes.id}`}
      src={attributes.src}
      alt={node.meta.label?.text}
      style={{
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
      }}
    />
  </>
)

const NodeImage = ({ node, attributes }: Props) => {
  if (node?.meta?.label?.id === 1050005) {
    return <QrCode node={node} attributes={attributes} />
  }

  return (
    <img
      data-testid={`node/image/${attributes.id}`}
      src={attributes.src}
      alt={node.meta.label?.text}
    />
  )
}

export default NodeImage
