import {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  useEffect,
  useState
} from 'react'

import type { FieldError, Ref } from 'react-hook-form'

import * as css from './Input.styles'
import { IInput, InputCounter } from './Input.types'
import InputError from './InputError'
import InputHintText from './InputHintText'
import InputLabel from './InputLabel'

const InputFieldRef = (
  {
    inputSize = 'lg',
    type = 'text',
    showCounter = false,
    hideLabel,
    id,
    labelProps,
    required,
    testid,
    disabled,
    error: errorProp,
    name,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    value: valueProp,
    currency,
    styles,
    foreground,
    label,
    icon,
    hintText,
    maxLength,
    minDate,
    checked,
    helpText
  }: IInput & InputCounter,
  ref?: ForwardedRef<HTMLInputElement>
) => {
  const [value, setValue] = useState(valueProp)
  const [error, setError] = useState<FieldError>(errorProp)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    setValue(newValue)

    const error =
      maxLength && newValue.length > maxLength
        ? {
            type: 'maxLength',
            message: `Maximum length is ${maxLength} characters`
          }
        : null
    setError(error)

    if (!onChange) return
    onChange(e)
  }

  useEffect(() => {
    setError(errorProp)
  }, [errorProp])

  useEffect(() => {
    if (valueProp === value) return

    setValue(valueProp)
  }, [valueProp])

  return (
    <div css={css.rootStyles({ currency, inputSize, styles, type })}>
      <InputLabel
        inputSize={inputSize}
        foreground={foreground}
        label={label}
        name={name}
        error={error}
        hideLabel={hideLabel}
        id={id}
        labelProps={labelProps}
        required={required}
        styles={styles}
      />
      {hintText && <InputHintText text={hintText} inputName={name} />}
      <div css={{ position: 'relative' }}>
        <div
          css={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <span css={css.iconContainerStyles(Boolean(error))}>{icon}</span>
          <input
            css={css.fieldStyles(
              {
                inputSize,
                styles,
                type,
                currency,
                error,
                foreground,
                label,
                name,
                ref: ref as Ref
              },
              Boolean(icon)
            )}
            aria-invalid={error ? 'true' : 'false'}
            aria-labelledby={`${name}-label`}
            data-testid={testid}
            disabled={disabled}
            id={name}
            name={name}
            onBlur={onBlur}
            onChange={handleChange}
            onFocus={onFocus}
            placeholder={placeholder}
            type={type}
            min={minDate}
            ref={ref}
            value={value}
            checked={checked}
          />
        </div>
      </div>
      {error && error?.type !== 'maxLength' && <InputError error={error} />}
      {!error && helpText && (
        <div css={css.helperText(false)}>
          <span>{helpText}</span>
        </div>
      )}
      {showCounter && (
        <div css={css.helperText(Boolean(error))}>
          <span>
            {value?.length || 0}/{maxLength}
          </span>
        </div>
      )}
    </div>
  )
}

const InputField = forwardRef(InputFieldRef)

InputField.displayName = 'Input'

export default InputField
