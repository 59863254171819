import { UiNode, UiNodeAnchorAttributes } from '@ory/client'

import Button from '@/ui/Button'

interface Props {
  node: UiNode
  attributes: UiNodeAnchorAttributes
}

const NodeAnchor = ({ attributes }: Props) => (
  <Button
    data-testid={`node/anchor/${attributes.id}`}
    onClick={e => {
      e.stopPropagation()
      e.preventDefault()
      window.location.href = attributes.href
    }}
  >
    {attributes.title.text}
  </Button>
)

export default NodeAnchor
