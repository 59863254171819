import { UiNode, UiNodeInputAttributes, UiText } from '@ory/client'

interface INewNodeInputData {
  label?: string
  name?: string
}

export const customizedNodeData = (
  node: UiNode,
  { label, name }: INewNodeInputData
) => ({
  ...node,
  meta: {
    ...node.meta,
    label: {
      ...node.meta.label,
      text: label || node.meta.label.text
    }
  },
  attributes: {
    ...node.attributes,
    name: name || (node.attributes as UiNodeInputAttributes).name
  }
})

export const errorMessage = (error: UiText) => {
  if (error.id === 4000034)
    return 'This password is very weak, please update it for your security.'
  return error.text.replace('can not', 'cannot')
}

export const isEmail = (node: UiNode) =>
  [1070007].includes(node?.meta?.label?.id)

export const isPasswordless = (node: UiNode) =>
  [1040006, 1010015].includes(node?.meta?.label?.id)

export const isPassword = (node: UiNode) =>
  (node.attributes as UiNodeInputAttributes).name === 'password'

export const isResendCode = (node: UiNode) =>
  [1070008].includes(node?.meta?.label?.id)
