import * as css from './Input.styles'
import { IInputLabel } from './Input.types'

const InputLabel = ({
  name,
  id,
  label,
  hideLabel,
  required,
  error,
  foreground,
  inputSize,
  labelProps,
  styles
}: IInputLabel) => (
  <label
    css={css.labelStyles({
      error,
      foreground,
      hideLabel,
      inputSize,
      labelProps,
      styles
    })}
    data-testid='input-label'
    id={`${name}-label`}
    htmlFor={id || name}
    aria-hidden={hideLabel ? 'true' : 'false'}
  >
    {label} {required && <span css={css.requiredLabel} />}
  </label>
)

export default InputLabel
