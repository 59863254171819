import { CSSProperties } from 'react'

import { createStyles } from 'utils/css'
import theme from 'utils/theme'

import { IInput, IInputLabelStyles, IInputWarningStyles } from './Input.types'
const { colors, size, spacing } = theme

export const rootStyles = ({
  currency,
  inputSize,
  styles,
  type
}: Pick<IInput, 'currency' | 'inputSize' | 'styles' | 'type'>) =>
  createStyles(({ colors, spacing, size }) => {
    const defaultStyles = {
      textAlign: 'left',
      color: colors.grey30
    }

    const setStyles = () => {
      switch (inputSize) {
        case 'sm':
          return {
            left: spacing(0.25),
            height: size(1.6)
          }
        case 'md':
          return {
            left: spacing(0.5),
            height: size(2.0)
          }
        case 'xl':
          return {
            left: spacing(1),
            height: size(3.4)
          }
        default:
          return {
            left: spacing(1),
            height: size(2.8)
          }
      }
    }
    const beforeStyles: CSSProperties = {
      color: colors.grey30,
      position: 'absolute',
      bottom: size(1),
      width: size(1.2),
      zIndex: 1,
      fontWeight: 600,
      ...setStyles()
    }
    const currencyStyles = currency
      ? {
          position: 'relative',
          '&:before': {
            content: '"$"',
            ...beforeStyles
          }
        }
      : {}
    const checkboxStyles =
      type === 'checkbox'
        ? ({
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'start',
            gap: '8px',
            alignItems: 'center'
          } as CSSProperties)
        : {}

    return {
      ...defaultStyles,
      ...currencyStyles,
      ...checkboxStyles,
      ...styles?.root
    }
  })

export const requiredLabel = () =>
  createStyles(({ colors, spacing }) => ({
    '&:after': {
      content: '"*"',
      paddingLeft: spacing(0.25),
      color: colors.danger
    }
  }))

export const labelStyles = ({
  error,
  hideLabel,
  inputSize,
  labelProps,
  styles
}: IInputLabelStyles) =>
  createStyles(({ colors, effects, inputs: { labels } }) => {
    const labelTreatment = hideLabel
      ? { ...effects.hide }
      : {
          fontSize: labels[inputSize],
          marginBottom: '0.25rem',
          ...labelProps
        }
    const defaultLabel = {
      color: error ? colors.danger : styles?.label?.color || colors.surface500,
      ...labelTreatment
    }
    return { ...defaultLabel, ...styles?.label }
  })

export const warningStyles = ({ inputSize, type }: IInputWarningStyles) => {
  const smallSize = inputSize === 'sm' || inputSize === 'md'
  return createStyles(({ colors, spacing }) => ({
    fill: colors.danger,
    background: colors.light,
    position: 'absolute',
    right: type === 'date' ? spacing(2.5) : spacing(0.25),
    top: spacing(inputSize === 'xl' ? 1 : 0.5),
    width: smallSize && spacing(1),
    height: smallSize && spacing(1)
  }))
}

export const fieldStyles = (
  { currency, error, foreground, inputSize, styles, type }: IInput,
  hasIcon?: boolean
) =>
  createStyles(
    ({
      colors,
      effects,
      inputs: { radii, sizes },
      spacing,
      treatments,
      typography
    }) => {
      const setCurrencyPadding = (vertical: number) => {
        switch (inputSize) {
          case 'sm':
          case 'md':
            return spacing([vertical, 1.5])
          default:
            return spacing([vertical, 2.25])
        }
      }

      const setPadding = (vertical: number) => {
        switch (type) {
          case 'select':
            return spacing([vertical, 1 + 0.5, vertical, 1])
          case 'textarea':
            return spacing([vertical + 0.5, 1])
          default:
            return currency
              ? setCurrencyPadding(vertical)
              : spacing([vertical, 1])
        }
      }

      const handleOverflow = type !== 'textarea' ? effects.ellipsis : {}

      const defaultField = {
        color: error ? colors.danger : colors[foreground],
        background: colors.light,
        border: `1px solid ${error ? colors.danger : colors.surface300}`,
        borderRadius: radii.sm,
        textAlign: 'left',
        height:
          type === 'textarea'
            ? `calc(${sizes[inputSize]} * 2)`
            : sizes[inputSize],
        lineHeight: type !== 'textarea' && sizes[inputSize],
        fontSize: typography.body.sizes[inputSize],
        padding:
          type === 'select' && inputSize === 'sm'
            ? setPadding(0)
            : setPadding(0.25),
        paddingLeft: hasIcon ? spacing(2.5) : undefined,
        width: '100%',
        ...handleOverflow,
        ...treatments.focus,
        '&:focus': {
          borderColor: error && colors.danger
        },
        '&:focus-visible': {
          outlineColor: error && colors.danger
        },
        '&::placeholder': {
          ...treatments.placeholder,
          color: colors.surface400
        },
        '&:disabled': {
          ...treatments.disabled,
          background: colors.grey15
        }
      }

      const selectStyles =
        type === 'select'
          ? {
              color: colors[foreground],
              appearance: 'none',
              backgroundImage: `linear-gradient(45deg, transparent 50%, ${colors.grey} 50%),linear-gradient(135deg, ${colors.grey}  50%, transparent 50%)`,
              backgroundPosition: 'calc(100% - 2.2rem), calc(100% - 1.6rem)',
              backgroundSize: '0.6rem 0.6rem, 0.6rem 0.6rem',
              backgroundRepeat: 'no-repeat',
              lineHeight: inputSize === 'lg' ? 'inherit' : 1.2,
              '&:disabled': {
                ...treatments.disabled,
                background: colors.grey15
              }
            }
          : {}

      return { ...defaultField, ...selectStyles, ...styles?.field }
    }
  )

export const numberField = {
  root: {
    position: 'relative',
    '&:before': {
      content: '"#"',
      color: colors.grey30,
      position: 'absolute',
      left: spacing(1),
      bottom: size(1),
      width: size(1.2),
      height: size(2.7),
      zIndex: 1,
      fontWeight: 600
    }
  },
  field: {
    paddingLeft: spacing(2)
  }
}

export const iconContainerStyles = (hasError: boolean) =>
  createStyles(({ colors }) => ({
    position: 'absolute',
    left: '16px',
    color: hasError ? colors.danger : colors.surface400,
    display: 'flex',
    alignItems: 'center'
  }))

export const errorContainerStyles = createStyles(({ typography, spacing }) => ({
  fontSize: typography.body.sizes.sm,
  color: colors.danger,
  marginTop: spacing(0.3),
  textAlign: 'right'
}))

export const helperText = (error: boolean) =>
  createStyles(({ typography, colors }) => ({
    textAlign: 'right',
    fontSize: typography.caption.sizes.md,
    color: error ? colors.danger : colors.surface400,
    paddingTop: spacing(0.3)
  }))
